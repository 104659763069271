<template>

<div class="mobile-page-layout font-IRANSans">

    <!-- <div class="mobile-page-header">
        <div class="mobile-page-header-row">
            <div class="mobile-page-header-right">
                <router-link :to="{ name: 'HomeIntro'}" class="mobile-page-header-icon pointer back-icon">
                    <i class="header-uil-icon uil uil-angle-right-b"></i>
                </router-link>
                <div class="mobile-page-header-icon">
                    <svg class="header-svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path class="fa-primary" d="M448 335.1c0 13.42-15.52 20.88-25.1 12.49L368 305.3l-54 43.2C303.5 356.8 288 349.4 288 335.1V96h160V335.1z" />
                        <path class="fa-secondary" d="M512 144v288c0 26.5-21.5 48-48 48h-416C21.5 480 0 458.5 0 432v-352C0 53.5 21.5 32 48 32h160l64 64H288v239.1c0 13.42 15.52 20.88 25.1 12.49L368 305.3l54 43.2C432.5 356.8 448 349.4 448 335.1V96h16C490.5 96 512 117.5 512 144z" />
                    </svg>
                </div>
                <div class="mobile-page-header-text">
                    دسته‌بندی اخبـار
                </div>  
            </div>
            <div class="mobile-page-header-actions">

                <div class="mobile-page-header-menu">
                    <template>
                        <div class="text-center">
                            <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="mobile-page-header-icon pointerv margin-0" v-bind="attrs" v-on="on">
                                    <i class="header-material-icon mdi mdi-dots-vertical"></i>
                                </div>
                            </template>
                            <v-list class="header-help-list">
                                <v-list-item @click.prevent="get_modal('search')">
                                    <v-list-item-title>جستجو</v-list-item-title>
                                </v-list-item>

                            </v-list>
                            </v-menu>
                        </div>
                    </template>                 
                </div>
            </div>
        </div>
    </div> -->




    <div class="home-layout">


        <div v-if="slider.items" class="news-slider">
            <router-link :to="{ name: 'HomeIntro'}" class="news-slider-back-btn">
                <i class="uil uil-angle-right-b"></i>
            </router-link>
            <img class="news-slider-img" :src="slider.items.data[0].image">
            <div class="news-slider-content">
                <router-link class="news-slider-tag" :to="{ name: 'ApplicationNews' , params: { category_id: slider_cat, }}">{{slider.items.data[0].category_title}}</router-link>  
                <router-link class="news-slider-title" :to="{ name: 'ApplicationNewsContent' , params: { id: slider.items.data[0].id, }}">{{ slider.items.data[0].title }}</router-link>
                <p class="news-slider-des">{{slider.items.data[0].summary}}</p>
            </div>
        </div>
        


        <div class="home-row">
            <!-- <div class="tgcss-w-full tgcss-flex tgcss-items-center  tgcss-p-2">
                <span class="tgcss-text-sm tgcss-font-bold grow">دسته‌بندی‌اخبار</span> 
            </div> -->

            <router-link :to="{ name: 'CategoryNews' , params: { category_id: 100003, }, query: { subject: 'دسته‌های خبری' }}" class="home-col xs:!tgcss-w-[97%] xs:!tgcss-h-[68px]">
                <div class="home-widget xs:!tgcss-text-right xs:!tgcss-justify-start xs:!tgcss-py-[8px] xs:!tgcss-px-[20px]"><div class="home-widget-in xs:!tgcss-flex xs:!tgcss-items-center ">
                    <img src="https://static.tgju.org/views/default/images/tgju/app/news/blue/دسته_های خبری.svg?01" class="home-widget-img xs:!tgcss-ml-[15px] xs:!tgcss-max-w-[35px]">
                    <h2 class="home-widget-title xs:!tgcss-mt-0 xs:!tgcss-text-[15px]">دسته‌های خبری</h2>
                </div></div>
            </router-link>

            <slot v-for="item in category">


                <router-link v-if="item.type == 'category'" class="home-col"  :class="{ ' f-w ': item.grid == 'span-2' }" :to="{ name: 'CategoryNews' , params: { category_id: item.id, }, query: { subject: item.id }}">
                    <div class="home-widget">
                        <div class="home-widget-in">
                            <img v-if="item.icon" class="home-widget-img" :src="item.icon">
                            <h2 class="home-widget-title">{{item.name}}</h2>
                        </div>
                    </div>  
                </router-link>  
                

                <router-link v-if="item.type == 'category-main'"  class="home-col"  :class="{ ' f-w ': item.grid == 'span-2' }" :to="{ name: 'ApplicationNews' , params: { category_id: item.id, }, query: { subject: item.name }}">
                    <div class="home-widget">
                        <div class="home-widget-in">
                            <img v-if="item.icon" class="home-widget-img" :src="item.icon">
                            <h2 class="home-widget-title">{{item.name}}</h2>
                        </div>
                    </div>  
                </router-link>  
                

                <router-link v-if="item.type == 'tag'"  class="home-col"  :class="{ ' f-w  ': item.grid == 'span-2' }" :to="{ name: 'ApplicationNews' , params: { category_id: item.slug, }, query: { subject: item.name }}">
                    <div class="home-widget">
                        <div class="home-widget-in">
                            <img v-if="item.icon" class="home-widget-img" :src="item.icon">
                            <h2 class="home-widget-title">{{item.name}}</h2>
                        </div>
                    </div>  
                </router-link>  
                

                <router-link v-if="item.type == 'page'"  class="home-col"  :class="{ ' f-w ': item.grid == 'span-2' }" :to="{ name: 'CategoryNews' , params: { category_id: item.id, }, query: { subject: 'tgju-news-' + item.id }}">
                    <div class="home-widget">
                        <div class="home-widget-in">
                            <img v-if="item.icon" class="home-widget-img" :src="item.icon">
                            <h2 class="home-widget-title">{{item.name}}</h2>
                        </div>
                    </div>  
                </router-link>  

            </slot>

        </div>

        <!-- <div class="tgcss-pt-3">
            <newsCategory :grid=true :cat="'اخبار-ویژه'" :count="20" :title="'دسته‌بندی‌اخبار'" :type="'slug'" />
        </div> -->
        <!-- <newsStories :cat="'اخبار-ویژه'" :count="20" :title="'اخبار ویژه'" :type="'slug'" /> -->
        

        <newsTabs :cat="mainTabCategory" :count="15"/>

        <!-- <newsBanner :cat="'84008'" :count="1" :title="''" :type="'id'" /> -->

        <!-- <newsTranding :id="100009" :cat="'97828'" :count="5" :title="'برورکرها'" :type="'id'" /> -->

        <!-- <newsPosts :cat="'اخبار-ویژه'" :count="5" :title="'جدیدترین ها'" :type="'slug'" /> -->

        <!-- <news2RowSlide :id="100005" :cat="'تحلیل-تکنیکال'" :count="30" :title="'تحلیل تکنیکال'" :type="'slug'" />

        <newsRowSlide :id="100008" :cat="'دانش-نامه'" :count="10" :title="'دانش‌نامه اقتصادی'" :type="'slug'" /> -->


        <!-- <div class="home-row">
            <router-link v-for="item in list" :key="item.id" :class="'home-col '+item.class" :to="{ name: 'ApplicationNews' , params: { category_id: item.id }}">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" :src="'https://static.tgju.org/views/default/images/tgju/app/news/'+item.image">
                        <h2 class="home-widget-title">{{item.name}}</h2>
                    </div>
                </div>  
            </router-link>  
        </div>
        <NewsRow :cat="'اخبار-ویژه'" :count="5" :title="'اخبـار ویـژه'" :type="'slug'" /> -->
    </div>
</div>
</template>

<script>
// import NewsRow from '@/components/Inc/newsRow.vue'
// import newsStories from '@/components/Inc/newsStories.vue'
//import news2RowSlide from '@/components/Inc/news2RowSlide.vue'
//import newsRowSlide from '@/components/Inc/newsRowSlide.vue'
//import newsBanner from '@/components/Inc/newsBanner.vue'
//import newsTranding from '@/components/Inc/newsTranding.vue'
// import newsPosts from '@/components/Inc/newsPosts.vue'
//import newsCategory from '@/components/Inc/newsCategory.vue'
import newsTabs from '@/components/Inc/newsTabs.vue'


export default {
    name: 'NewsIntro',
    components: {
        // NewsRow,
        // newsStories,
        // news2RowSlide,
        // newsRowSlide,
        // newsBanner,
        // newsTranding,
        // newsPosts,
        // newsCategory,
        newsTabs
    },
    data: function () {
        return {
            list:[
                {
                    id:'اخبار-ویژه',
                    name:'اخبار ویژه',
                    image:'ویژه.svg'
                },
                {
                    id:'تحلیل-بنیادین',
                    name:'تحلیل بنیادین',
                    image:'تحلیل بنیادین.svg'
                },
                {
                    id:'تحلیل-تکنیکال',
                    name:'تحلیل تکنیکال',
                    image:'تحلیل تکنیکال.svg'
                },
                {
                    id:84008,
                    name:' گزارش بازارها',
                    image:'گزارش بازارها.svg'
                },
                {
                    id:93970,
                    name:'  آکادمی',
                    image:'آکادمی.svg'
                },
                {
                    id:93964,
                    name:'طلا و سکه',
                    image:'طلا و سکه.svg'
                },
                {
                    id:93965,
                    name:'ارزی',
                    image:'ارزی.svg'
                },
                {
                    id:93966,
                    name:'ارزهای دیجیتال',
                    image:'ارزهای دیجیتال.svg'
                },
                {
                    id:93967,
                    name:'نفت و انرژی',
                    image:'انرژی.svg'
                },
                {
                    id:93968,
                    name:'بورس',
                    image:'بورس.svg'
                },
                {
                    id:83979,
                    name:'مسکن',
                    image:'مسکن.svg'
                },
                {
                    id:83980,
                    name:'خودرو',
                    image:'خودرو.png'
                },
                {
                    id:93969,
                    name:'بانکی',
                    image:'بانک و بیمه.svg'
                },
                {
                    id:83986,
                    name:'تولید و تجارت',
                    image:'تولید و تجارت.svg'
                },
                {
                    id:83983,
                    name:'جهان',
                    image:'جهان.svg'
                },
                {
                    id:83981,
                    name:'قیمت‌ها',
                    class: 'home-col-local-markets',
                    image:'قیمت ها.svg'
                },
                {
                    id:83984,
                    name:'فناوری',
                    class: 'home-col-local-markets',
                    image:'فناوری.svg'
                }
            ],
            mainCatList:[
                    {
                        id:4,
                        type:'tag',
                        name:'اخبار ویژه',
                        slug:'اخبار-ویژه',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/%D9%88%DB%8C%DA%98%D9%87.svg',
                    },
                    {
                        id:1,
                        type:'page',
                        name:'دیده‌بان بازار',
                        slug:'live',
                        icon:'',
                    },
                    {
                        id:83978,
                        type:'category',
                        name:'دسته‌های خبری',
                        slug:'دسته-های-خبری',
                        icon:'',
                    },
                    {
                        id:5,
                        type:'tag',
                        name:'تحلیل بنیادین',
                        slug:'تحلیل-بنیادین',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/%D8%AA%D8%AD%D9%84%DB%8C%D9%84%20%D8%A8%D9%86%DB%8C%D8%A7%D8%AF%DB%8C%D9%86.svg',
                    },
                    {
                        id:6,
                        type:'tag',
                        name:'تحلیل تکنیکال',
                        slug:'تحلیل-تکنیکال',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/%D8%AA%D8%AD%D9%84%DB%8C%D9%84%20%D8%AA%DA%A9%D9%86%DB%8C%DA%A9%D8%A7%D9%84.svg',
                    },
                    {
                        id:84008,
                        type:'category',
                        name:'گزارش‌ بازارها',
                        slug:'گزارش-بازارها',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/%DA%AF%D8%B2%D8%A7%D8%B1%D8%B4%20%D8%A8%D8%A7%D8%B2%D8%A7%D8%B1%D9%87%D8%A7.svg',
                    },
                    {
                        id:93970,
                        type:'category',
                        name:'آکادمی',
                        slug:'آکادمی',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/%D8%A2%DA%A9%D8%A7%D8%AF%D9%85%DB%8C.svg',
                    },
                    {
                        id:97049,
                        type:'category',
                        name:'دانش نامه',
                        slug:'دانش-نامه',
                        icon:'',
                    },
                    {
                        id:97816,
                        type:'category',
                        name:'بروکرها',
                        slug:'بروکرهای-فارکس',
                        icon:'',
                    },
                    {
                        id:97829,
                        type:'category',
                        name:'ویکی‌کریپتو',
                        slug:'ویکی-کریپتو',
                        icon:'',
                    },
                    {
                        id:2,
                        type:'page',
                        name:'مفاهیم‌،اصطلاحات',
                        slug:'glossary',
                        icon:'',
                    },
                    {
                        id:84006,
                        type:'category',
                        name:'وبلاگ',
                        slug:'مشاور-خرید',
                        icon:'',
                    },
                    {
                        id:97086,
                        type:'category',
                        name:'مولتی‌مدیا',
                        slug:'مولتی-مدیا',
                        icon:'',
                    },
                ],
            mainTabCategory:[
                {
                    id:4,
                    type:'tag',
                    name:'اخبار ویژه',
                    slug:'اخبار-ویژه',
                    icon:'https://static.tgju.org/views/default/images/tgju/app/size.png',
                },
                // {
                //     id:1,
                //     type:'live',
                //     name:'دیده‌بان بازار',
                //     slug:'live',
                //     icon:'https://static.tgju.org/views/default/images/tgju/app/size.png',
                // },
                // {
                //     id:83978,
                //     type:'category',
                //     name:'دسته‌های خبری',
                //     slug:'دسته-های-خبری',
                //     icon:'https://static.tgju.org/views/default/images/tgju/app/size.png',
                // },
                {
                    id:5,
                    type:'tag',
                    name:'تحلیل بنیادین',
                    slug:'تحلیل-بنیادین',
                    icon:'https://static.tgju.org/views/default/images/tgju/app/size.png',
                },
                {
                    id:6,
                    type:'tag',
                    name:'تحلیل تکنیکال',
                    slug:'تحلیل-تکنیکال',
                    icon:'https://static.tgju.org/views/default/images/tgju/app/size.png',
                },
                {
                    id:84008,
                    type:'category',
                    name:'گزارش‌ بازارها',
                    slug:'گزارش-بازارها',
                    icon:'',
                },
                {
                    id:93979,
                    type:'category',
                    name:'آکادمی',
                    slug:'آکادمی',
                    icon:'',
                },
                {
                    id:97053,
                    type:'category',
                    name:'دانش نامه',
                    slug:'دانش-نامه',
                    icon:'',
                },
                {
                    id:97828,
                    type:'category',
                    name:'بروکرها',
                    slug:'بروکرهای-فارکس',
                    icon:'',
                },
                {
                    id:97839,
                    type:'category',
                    name:'ویکی‌کریپتو',
                    slug:'ویکی-کریپتو',
                    icon:'',
                },
                // {
                //     id:2,
                //     type:'page',
                //     name:'مفاهیم و اصطلاحات',
                //     slug:'glossary',
                //     icon:'',
                // },
                {
                    id:84006,
                    type:'category',
                    name:'وبلاگ',
                    slug:'مشاور-خرید',
                    icon:'',
                },
                // {
                //     id:97086,
                //     type:'category',
                //     name:'مولتی‌مدیا',
                //     slug:'مولتی-مدیا',
                //     icon:'',
                // },
            ],
            slider:[],
            slider_cat:0,
            category:[
                    // {
                    //     id:100003,
                    //     type:'category',
                    //     name:'دسته‌های خبری',
                    //     slug:'دسته-های-خبری',
                    //     icon:'https://static.tgju.org/views/default/images/tgju/app/news/blue/دسته_های خبری.svg?01',
                    //     grid:'span-2'

                    // },
                    {
                        id:100001,
                        type:'tag',
                        name:'اخبار ویژه',
                        slug:'اخبار-ویژه',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/blue/%D9%88%DB%8C%DA%98%D9%87.svg?4',
                    },
                    // {
                    //     id:100002,
                    //     type:'page',
                    //     name:'دیده‌بان بازار',
                    //     slug:'live',
                    //     icon:'https://static.tgju.org/views/default/images/tgju/app/news/دیده‌بان بازار.svg',
                    // },
                    {
                        id:100004,
                        type:'tag',
                        name:'تحلیل بنیادین',
                        slug:'تحلیل-بنیادین',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/blue/%D8%AA%D8%AD%D9%84%DB%8C%D9%84%20%D8%A8%D9%86%DB%8C%D8%A7%D8%AF%DB%8C%D9%86.svg?1',
                    },
                    {
                        id:100005,
                        type:'category',
                        name:'تحلیل تکنیکال',
                        slug:'تحلیل-تکنیکال',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/blue/%D8%AA%D8%AD%D9%84%DB%8C%D9%84%20%D8%AA%DA%A9%D9%86%DB%8C%DA%A9%D8%A7%D9%84.svg?1',
                    },
                    {
                        id:84008,
                        type:'category-main',
                        name:'گزارش‌ بازارها',
                        slug:'گزارش-بازارها',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/blue/%DA%AF%D8%B2%D8%A7%D8%B1%D8%B4%20%D8%A8%D8%A7%D8%B2%D8%A7%D8%B1%D9%87%D8%A7.svg?5544',
                    },
                    {
                        id:100007,
                        type:'category',
                        name:'آکادمی',
                        slug:'آکادمی',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/blue/%D8%A2%DA%A9%D8%A7%D8%AF%D9%85%DB%8C.svg?4',
                    },
                    {
                        id:100008,
                        type:'category',
                        name:'دانش نامه',
                        slug:'دانش-نامه',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/blue/دانش نامه.svg?1',
                    },
                    {
                        id:100009,
                        type:'category',
                        name:'بروکرها',
                        slug:'بروکرهای-فارکس',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/blue/بروکرها.svg?4',
                    },
                    {
                        id:100010,
                        type:'category',
                        name:'ویکی‌کریپتو',
                        slug:'ویکی-کریپتو',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/blue/ویکی_کریپتو.svg?7',
                    },
                    // {
                    //     id:100011,
                    //     type:'page',
                    //     name:'مفاهیم‌،اصطلاحات',
                    //     slug:'glossary',
                    //     icon:'https://static.tgju.org/views/default/images/tgju/app/news/مفاهیم‌،اصطلاحات.svg',
                    // },
                    {
                        id:100012,
                        type:'category',
                        name:'وبلاگ',
                        slug:'مشاور-خرید',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/blue/وبلاگ.svg?00551',
                    },
                    // {
                    //     id:100013,
                    //     type:'category-main',
                    //     name:'مولتی‌مدیا',
                    //     slug:'مولتی-مدیا',
                    //     icon:'https://static.tgju.org/views/default/images/tgju/app/news/مولتی‌مدیا.svg',
                    // },
                ]
        }
    },
    mounted() {
        this.getSlider();
        this.$store.commit('setAds', true);
    },
    computed: {},
    methods: {
        getSlider(){
            $.ajax({
                url: 'https://api.tgju.org/v1/news/list?limit=1&tag=%D8%AE%D8%A8%D8%B1-%DB%8C%DA%A9',
                method: 'get',
                complete: (res) => {
                    this.slider = res.responseJSON.response;    
                    this.slider_cat = Object.keys(this.slider.categories)[0];   
                }
            });
        },
    },
}
</script>